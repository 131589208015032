<template>
    <div class="card">
        <div class="card__amountWrap" :class="{'card__amountWrap--open':isAdded, 'card__amountWrap--animation':isAmountAnimation}" >
            <p>{{ modelValue > 0 ? modelValue : 1 }}</p>
        </div>
        <img :src="cardData.thumbnail" alt="" >
        <div class="card__row">
            <h2>{{cardData.name}}</h2>
            <p>•</p>
            <p>{{cardData.price}} ₽</p>

        </div>
        <div class="card__buttonWrap">
            <div @click="clickAddButton" class="card__buttonWrap_button" :class="{'card__buttonWrap_button--compress' : isAdded}">
                <p class="card__buttonWrap_button-text">Купить</p>
                <p class="card__buttonWrap_button-add">+</p>
            </div> 
            <div @click="clickRemoveButton" class="card__buttonWrap_button card__buttonWrap_button-minus" :class="{'card__buttonWrap_button-minus--open' : isAdded}">
                <p class="card__buttonWrap_button-minus-add">-</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        cardData: Object,
        modelValue: Number,
    },
    data() {
        return {
            isAmountAnimation: false,
        }
    },
    methods: {
        clickAddButton() {
            this.$emit("update:modelValue", this.modelValue + 1)
        },
        clickRemoveButton() {
            this.$emit("update:modelValue", this.modelValue - 1)
        },
        animationAmount(){
            this.isAmountAnimation = true
            setTimeout(() => {
                this.isAmountAnimation = false
            }, 200)
        },
    },
    watch: {
        modelValue(value, oldValue) {
            this.$emit('updateAllPrice')
            if(oldValue !== 0 && value !== 0) {
                this.animationAmount()
            }
        }
    },
    computed: {
        isAdded(){
            return this.modelValue > 0
        }
    },
}
</script>
<style lang="scss">
    $transition: 0.2s;
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        &__amountWrap{
            position: absolute;
            right: 5vw;
            top: 1vw;
            display: flex;
            justify-content: center;;
            align-items: center;
            width: 7vw;
            height: 7vw;
            border-radius: 50%;
            color: white;
            background: orange;
            transform: scale(0);
            transition-duration: .12s;
            transition-timing-function: cubic-bezier(.17,.93,.97,1.29);
            &--open{
                transform: scale(1);
            }
            &--animation{
                animation: amount-animation .2s ease-out forwards;
            }
            & > p{
                @include baseText(4vw);
                color: white;
            }
        }
        &__row{
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            gap: 1.5vw;
            align-items: center;
            height: auto;
            & > h2{
                @include baseText;
                color: var(--tg-theme-text-color);
                text-align: center
            }
            & > p{
                @include baseText;
                white-space: nowrap;
                color: var(--tg-theme-text-color);
            }
        }
        & > img{
            width: 40vw;
            height: 40vw;
            margin-bottom: 4vw;
            object-fit: contain;
        }
        &__buttonWrap{
            position: relative;
            margin-top: 0;
            height: 12vw;
            width: 60%;
            flex-grow: 1;
            &_button {
                width: 100%;
                height: 9vw;
                text-align: center;
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 2vw 0;
                display: flex;
                align-content: center;
                justify-content: center;
                border-radius: 2vw;
                @include baseText;
                background: var(--mainColor);
                color: white;
                cursor: pointer;
                transition: $transition;
                z-index: 2;
                line-height: 4.2vw;
                vertical-align: center;
                &-minus{
                    z-index: 1;
                    width: 45%;
                    left: 0;
                    transform: scale(0.8);
                    transition: $transition;
                    background: var(--warning);
                    transition-timing-function: cubic-bezier(.17,.89,.97,1.39);
                    &--open{
                        transform: scale(1);
                    }
                    &-add{
                        transition: $transition;
                        font-size: 7vw;
                        line-height: 0.5em;
                        // color: var(--tg-theme-text-color);
                    }
                }
                &-text{
                    transition: $transition;
                    line-height: 4.6vw;
                    position: absolute;
                }
                &-add{
                    transform: scale(0);
                    transition: .4s;
                    font-size: 7vw;
                    line-height: 0.7em;
                    
                }
                &--compress{
                    width: 45%;
                    .card__buttonWrap_button{
                        &-text{
                            transform: scale(0) translateX(1vw);
                        }
                        &-add{
                            transform: scale(1);
                        }
                    }
                    // letter-spacing: -0.4em;
                }
                &:hover{
                    // background: var(--mainColorHover);
                }
                &:active{
                    // transition: .1s background-color;
                    filter: saturate(200%);
                }
            }
        }
    }
    
    @keyframes amount-animation{
        0%, 100%{
            transform: scale(1);
        }
        50%{
            transform: scale(1.1);
        }
    }
</style>