<template>
  <HomeView  />
  <!-- <router-view>
  </router-view> -->
</template>
<script>
import HomeView from '@/views/HomeView.vue'
export default {
  components: {
    HomeView,
  },
  data(){
    return{
    }
  },
  provide(){
    return{
      // theme: {color: this.color, grad: this.grad},
      // color: false,
      // grad: false,
    }
  },
  created(){
    window.addEventListener('load', ()=>{
      console.log('document loaded');
      this.isLoaded = true
    }) 
  },
   methods:{
    openRequest(){
      console.log('openRequest');
      this.isOpenModal = true
    },
    scrollTop(){
      this.triggerToShowHeader++
    },
    scroll(idx){
      // this.
      console.log(idx);
      this.isOpenMobileMenu = false
      this.triggerToShowHeader++
      setTimeout(() => {
          let el;
          switch (idx){
              case 0:
                  el = document.getElementsByClassName('gram__wrap')[0]
                  break
              case 1:
                  el = document.getElementsByClassName('solution__wrap')[0]
                  break
              case 2:
                  el = document.getElementsByClassName('func__wrap')[0]
                  break
              case 3:
                  el = document.getElementsByClassName('offer__wrap')[0]
                  break
          }
          console.log('el', el);
          window.scroll({
              top: el.getBoundingClientRect().top + window.pageYOffset - 80,
              behavior: 'smooth',
          })

      }, 50)
    },
    toggleMobileWrap(){
      console.log('toggleMobileWrap');
      this.isOpenMobileMenu = !this.isOpenMobileMenu
    },
   },
   watch:{
   },
};
</script>

<style lang="scss">
@import '@/style/template.scss';
@import '@/style/reset.scss';



/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
// @font-face {
//   font-family: "Montserrat";
//   src: url("@/fonts/Montserrat.ttf") format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }

@font-face {
  font-family: "Montserrat-bd";
  src: url("@/fonts/Montserrat-bd.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("@/fonts/Montserrat-md.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "CeraPro";
  src: url("@/fonts/CeraPro.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "CeraProRegular";
  src: url("@/fonts/CeraPro-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ArialNova";
  src: url("@/fonts/ArialNova.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ArialBold";
  src: url("@/fonts/ArialNova-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar";
  src: url("@/fonts/Halvar.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar-bd";
  src: url("@/fonts/Halvar_bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar-th";
  src: url("@/fonts/Halvar_thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

</style>
