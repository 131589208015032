import { useApi } from '@/api/useApi.js'
// import { reject } from 'core-js/fn/promise';

export async function getPrice() {
    return new Promise((resolve, reject) => {
        let data;
        useApi('/api/cp/delivery-price/')
            .then((r) => {
                data = Math.round(r.data.data.price / 100)
                resolve(data)
            })
    })
}


