<template>
  
  <div class="main__categoryWrap">
    <div class="main__categoryWrap_allWrap">
      <div
        v-for="(category, idx) in categories"
        @click="chooseCategory(category.id)"
        :key="idx"
        class="main__categoryWrap_allWrap-category"
        :class="{
          'main__categoryWrap_allWrap-category--active':
          activeCategoryId == category.id,
        }"
      >
        {{ category.name }}
      </div>
    </div>
    <div class="preload-image">
      <img v-for="(img, idx) in allImages" :key="idx" :src="img">
    </div>
  </div>
  <div v-if="isShowCards" class="main__cards">
    <!-- {{ log }} -->
    <CardApp
      v-for="(card, idx) in currentCards"
      @updateAllPrice="updateAllPrice"
      v-model="card.amount"
      :cardData="card"
      :key="card.id"
    />

  </div>
  <div v-if="true" class="main__bottom">
    <div class="main__bottom_text">Стоимость доставки - {{deliveryPrice}}р</div>
  </div>
</template>
<script>
import CardApp from "@/components/CardApp.vue";
import { getCategories } from "@/api/getCategories";
import { getPrice } from "@/api/getPrice";
const Teleg = window.Telegram.WebApp
export default {
  components: {
    CardApp,
  },
  data() {
    return {
      tg: null,
      log: null,
      activeCategoryId: 0,
      isShowCards: true,
      categories: ["Пиво", "Чипсы", "Сигареты", "Вино"],
      allImages: [],
      cards: [
        [
          {
            name: "Heineken",
            price: "150",
            img: require("@/assets/prod/1.png"),
            amount: 0,
          },
          {
            name: "Балтика",
            price: "130",
            img: require("@/assets/prod/2.png"),
            amount: 0,
          },
          {
            name: "Zotecky",
            price: "140",
            img: require("@/assets/prod/3.png"),
            amount: 0,
          },
          {
            name: "Bud",
            price: "150",
            img: require("@/assets/prod/4.png"),
            amount: 0,
          },
          {
            name: "Kozel",
            price: "130",
            img: require("@/assets/prod/4.png"),
            amount: 0,
          },
        ],
        [
          {
            name: "Барбекю",
            price: "130",
            img: require("@/assets/prod/snack/1.png"),
            amount: 0,
          },
          {
            name: "Колбаски",
            price: "130",
            img: require("@/assets/prod/snack/2.png"),
            amount: 0,
          },
          {
            name: "Паприка",
            price: "130",
            img: require("@/assets/prod/snack/3.png"),
            amount: 0,
          },
          {
            name: "Укроп",
            price: "130",
            img: require("@/assets/prod/snack/4.png"),
            amount: 0,
          },
        ],
        [
          {
            name: "Philip morris",
            price: "130",
            img: require("@/assets/prod/cig/1.png"),
            amount: 0,
          },
          {
            name: "Malboro",
            price: "130",
            img: require("@/assets/prod/cig/2.webp"),
            amount: 0,
          },
          {
            name: "Winston",
            price: "130",
            img: require("@/assets/prod/cig/3.jpg"),
            amount: 0,
          },
          {
            name: "Camel",
            price: "130",
            img: require("@/assets/prod/cig/4.png"),
            amount: 0,
          },
          {
            name: "Rothmans",
            price: "130",
            img: require("@/assets/prod/cig/5.png"),
            amount: 0,
          },
        ],
        [
          {
            name: "Esmeralda",
            price: "250",
            img: require("@/assets/prod/wine/1.png"),
            amount: 0,
          },
          {
            name: "Campo Viejo",
            price: "270",
            img: require("@/assets/prod/wine/2.png"),
            amount: 0,
          },
          {
            name: "Campo Viejo",
            price: "270",
            img: require("@/assets/prod/wine/3.webp"),
            amount: 0,
          },
          {
            name: "Tapiche",
            price: "320",
            img: require("@/assets/prod/wine/4.webp"),
            amount: 0,
          },
          {
            name: "Dioscuria",
            price: "290",
            img: require("@/assets/prod/wine/5.png"),
            amount: 0,
          },
        ],
        [
          {
            name: "Леденцы",
            price: "20",
            img: require("@/assets/products/17.png"),
            amount: 0,
          },
          {
            name: "Чупа-чупс",
            price: "10",
            img: require("@/assets/products/16.png"),
            amount: 0,
          },
          {
            name: "Суши",
            price: "80",
            img: require("@/assets/products/25.png"),
            amount: 0,
          },
          {
            name: "Баранка",
            price: "15",
            img: require("@/assets/products/23.png"),
            amount: 0,
          },
          {
            name: "Яичница",
            price: "79",
            img: require("@/assets/products/10.png"),
            amount: 0,
          },
          {
            name: "Шницель",
            price: "259",
            img: require("@/assets/products/24.png"),
            amount: 0,
          },
        ],
      ],
      
      currentCards: [],
      allPrice: 0,
      deliveryPrice: null,
    };
  },
  created() {
    this.tg = window.Telegram.WebApp;
    this.cards.forEach(list => {
      list.forEach(card => {
        this.allImages.push(card.img)
      });
    })

    getCategories()
    .then(r => {
      this.categories = r
      this.chooseCategory(this.categories[0].id)
    })

    getPrice()
    .then(r => {
      this.deliveryPrice = r
    })


    let counter = 0
    this.cards.forEach((list) => {
        list.forEach((item) => {
            item.id = counter;
            counter++
        })
    })
    console.log(this.tg);
    this.log = this.tg.initDataUnsafe?.user?.username;
    // alert(this.name);
    // setTimeout(() => {
    //     console.log(this.cards);
    // }, 10000)
  },
  mounted() {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.onEvent("mainButtonClicked", this.sendData);
  },
  methods: {
    updateAllPrice() {
      let sum = 0;
      this.categories.forEach(category => {
        category.products.forEach(product => {
              console.log(product);
              sum += product.amount * product.price;
              console.log(sum);

        })
      })
      console.log(sum);
      this.allPrice = sum;
      this.updateButton();
    },
    updateButton() {
      if (this.allPrice > 0) {
        console.log('this.allPrice', this.allPrice);
        try {
          window.Telegram.WebApp.MainButton.setParams({
            text: "Заказать на " + (this.allPrice + this.deliveryPrice) + "р",
          });
          window.Telegram.WebApp.MainButton.show();

          this.log = "success";
        } catch (e) {
          this.log = e;
        }
      } else {
        window.Telegram.WebApp.MainButton.hide()
      }
    },
    sendData() {
      this.log="НАЖАЛ"
      const orderList = [];
      this.categories.forEach(category => {
        category.products.forEach((product) => {
            if (product.amount > 0) {
              orderList.push({
                id: product.id,
                name: product.name,
                // price: item.price,
                amount: product.amount,
              })
            }
        })

      })
      this.log = orderList
      const payload = {
        list: orderList,
        price: this.allPrice + this.deliveryPrice,
      }
      this.log = payload;
      window.Telegram.WebApp.sendData(JSON.stringify(payload));
    },
    chooseCategory(id){
      console.log(id);
      this.currentCards = this.categories.find(category => category.id == id).products
      this.activeCategoryId = id;
    }
  },
  watch: {
    activeCategoryId() {
      window.scrollTo(0, 0);
    }
  },
};
</script>
<style lang="scss">
.main {
  &__categoryWrap {
    width: 100%;
    padding: 0 5vw;
    height: auto;
    position: fixed;
    background: var(--tg-theme-bg-color);
    z-index: 20;
    &_allWrap {
      width: auto;
      display: flex;
      margin-top: 3vw;
      padding-bottom: 3vw;
      overflow-x: scroll;
      &-category{
        @include baseText(4vw);
        padding: 3vw 5vw;
        border-radius: 2vw;
        background: #8b8b8b3f;
        color: var(--tg-theme-text-color);
        font-size: 3.6vw;
        transition: 0.14s;
        &:not(:last-child){
          margin-right: 4vw;
        }
        &--active {
          background: var(--mainColor);
          color: white;
        }
      }
    }
  }
  &__cards {
    margin-top: 14vw;
    padding: 5vw 5vw 15vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10vw;
  }
  &__bottom{
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 7vw;
    background: rgb(247, 247, 247);
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &_text{
      @include baseText(3vw);
      color: rgb(160, 160, 160);
    }
  }
}
.preload-image{
  position: fixed;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  & > img{
    opacity: 0;
    pointer-events: none;
  }
}
</style>